import React, { FC, useEffect, useState } from 'react';
import { PrimaryButton, Seo } from 'components';
import Header from 'components/Header';
import Text from 'components/Text';
import { useRouter } from 'apis/history';
import styled from 'styled-components';
import { usePageView, useQuizData } from 'utils/hooks';
import { tablet, useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import Mark from 'assets/icons/red-mark.svg';
import Arrow from 'assets/icons/quiz/personal-summary-arrow.svg';
import quizSvgs from 'utils/constants/quizSvgs';
import ResultsProgress from 'components/ResultsProgress';

const ResultsSummary: FC = () => {
  const { goToResultsFatBurning } = useRouter();
  const data = useQuizData('resultsSummary');
  const [animate, setAnimate] = useState(false);
  const [leftPosition, setLeftPosition] = useState('0%');
  const { isMobile, isTablet } = useQuery();
  const quizAnswers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );

  const age = Number(quizAnswers?.age?.year) + 9;

  useEffect(() => {
    setAnimate(true);
  }, []);

  const bmi = Number(quizAnswers?.current_weight?.bmi);

  const renderSvgImage = (svgKey: string) => {
    const Svg = quizSvgs[svgKey];
    return <Svg />;
  };

  const resolveBoxInfo = () => {
    switch (true) {
      case bmi < 18.5:
        return {
          border: '#6DCAED',
          bg: '#E0F1FE',
          leftPosition: `4%`,
          img: 'blueMark',
          type: 'Your weight category: <span>Underweight</span>',
          typeColor: '#6DCAED',
        };

      case bmi >= 18.5 && bmi < 25:
        return {
          border: '#3FA134',
          bg: '#E1FCDA',
          leftPosition: `30%`,
          img: 'greenCheck',
          type: 'Your weight category: <span>Healthy</span>',
          typeColor: '#52AA48',
        };

      case bmi >= 25 && bmi < 30:
        return {
          border: '#DBCB91',
          bg: '#FDF8E2',
          leftPosition: isMobile ? `55%` : `57%`,
          img: 'yellowMark',
          type: 'Your weight category: <span>Overweight</span>',
          typeColor: '#ED9E53',
        };

      case bmi >= 30 && bmi < 35:
        return {
          border: '#F8766D',
          bg: '#FDE5E2',
          leftPosition: isMobile ? `78%` : `82%`,
          img: 'redMark',
          type: 'Your weight category: <span>Obese</span>',
          typeColor: '#DC4B4B',
        };
      case bmi > 35:
        return {
          border: '#F8766D',
          bg: '#FDE5E2',
          leftPosition: isMobile ? `78%` : `82%`,
          img: 'redMark',
          type: 'Your weight category: <span>Severely obese</span>',
          typeColor: '#DC4B4B',
        };
    }
  };

  useEffect(() => {
    const resolvedInfo = resolveBoxInfo();
    if (resolvedInfo) {
      setLeftPosition(resolvedInfo.leftPosition);
    }
    setAnimate(true);
  }, []);

  return (
    <>
      <Seo />
      <Outter>
        <Header sticky={false} />
        <ResultsProgress step={1} />
      </Outter>
      <Main>
        <Container>
          <Title>{data?.label}</Title>
          <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
          <Content>
            <TopTitleContainer>
              <SectionTitle>{data?.topTitle}</SectionTitle>
              <KmiText>Normal - 21.5</KmiText>
            </TopTitleContainer>
            <ChartContainer>
              <BubbleContainer animate={animate} leftPosition={leftPosition}>
                <YouText bgColor={resolveBoxInfo()?.typeColor as string}>
                  You - {bmi}
                  <Arrow />
                </YouText>
                <Bubble bgColor={resolveBoxInfo()?.typeColor as string} />
              </BubbleContainer>

              <Line />
              <Levels>
                {data?.levelTexts.map((item, index) => (
                  <LevelText key={index}>{item}</LevelText>
                ))}
              </Levels>
            </ChartContainer>

            <BoxInfo
              bgColor={resolveBoxInfo()?.bg as string}
              border={resolveBoxInfo()?.border as string}
            >
              {renderSvgImage(resolveBoxInfo()?.img as string)}
              <BoxInner
                dangerouslySetInnerHTML={{
                  __html: resolveBoxInfo()?.type,
                }}
              />
            </BoxInfo>
          </Content>
          <Content>
            <TopTitleContainer>
              <SectionTitle>{data?.metaboclicTitle}</SectionTitle>
              <MetabolicAge>{age} years</MetabolicAge>
            </TopTitleContainer>

            <BoxInfoBottom bgColor={resolveBoxInfo()?.typeColor as string}>
              <Mark />
              <BoxInner
                dangerouslySetInnerHTML={{ __html: data?.metabolicText }}
              />
            </BoxInfoBottom>
          </Content>
          <ButtonContainer>
            <ContinueButtonStyled onClick={goToResultsFatBurning}>
              {data?.buttonTitle}
            </ContinueButtonStyled>
          </ButtonContainer>
        </Container>
      </Main>
    </>
  );
};

export default ResultsSummary;

const Main = styled.section`
  min-height: calc(100vh - 76px);
  background: #faf9f7;
  @media ${tablet} {
    min-height: calc(100vh - 68px);
  }
`;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const Container = styled.div`
  padding: 2rem 1rem 2rem;
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
    padding: 1.5rem 1rem 6rem;
  }
`;

const Title = styled.h2`
  color: #141515;
  text-align: center;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: 100%;
  span {
    color: #4cb944;
  }
  padding-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const SectionTitle = styled.p`
  color: #141515;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 28.75rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin: 0 auto 1.5rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 6px 12px 3px rgba(29, 36, 48, 0.05);
  position: relative;
`;

const TopTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ChartContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3.13rem;
  @media ${tablet} {
    margin-top: 2.62rem;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 0.52006rem;
  border-radius: 625rem;
  background: linear-gradient(
    90deg,
    #c1dbe3 0%,
    #bff4a4 30.27%,
    #f3ed80 64.06%,
    #fbbf77 80.44%,
    #fc826e 100%
  );
`;

const Levels = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  margin: 0 1.2rem;
  @media ${tablet} {
    margin: 0 1rem;
  }
`;

const LevelText = styled.p`
  color: #727373;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const BubbleContainer = styled.div<{ animate: boolean; leftPosition: string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.57rem;
  top: -40px;
  left: ${({ leftPosition }) => leftPosition};
  transition: left 1.5s ease-out;
  @media ${tablet} {
    top: -38px;
  }
`;

const YouText = styled.div<{ bgColor: string }>`
  display: inline-flex;
  padding: 0.25rem 0.375rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #727373;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  position: relative;
  text-wrap: nowrap;
  svg {
    position: absolute;
    bottom: -6px;
    path {
      fill: #727373;
    }
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const Bubble = styled.div<{ bgColor: string }>`
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 4.878px solid #727373;
  border-radius: 3rem;
`;

const MetabolicAge = styled.p`
  color: #f04d41;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const KmiText = styled(Text)`
  color: #777;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const BoxInfo = styled.div<{ bgColor: string; border: string }>`
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  width: 100%;
  background: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ border }) => border};
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-wrap: nowrap;
  margin-top: 1.5rem;
  span {
    font-weight: 600;
    margin-left: 2px;
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    background: #faf9f7;
  }
`;

const BoxInner = styled.section`
  display: flex;
  color: #141515;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  span {
    font-weight: 700;
  }
`;

const Subtitle = styled.p`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 0.5rem;
  width: 100%;
  text-align: center;
  span {
    font-weight: 700;
  }
  padding-bottom: 1.5rem;
  @media ${tablet} {
  }
`;
const BoxInfoBottom = styled(BoxInfo)`
  border: 1px solid #f8766d;
  background: #fde5e2;
`;
