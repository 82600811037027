import greenCheck from 'assets/icons/green-checkmark.svg';
import blueMark from 'assets/icons/blue-mark.svg';
import redMark from 'assets/icons/red-mark.svg';
import yellowMark from 'assets/icons/yellow-mark.svg';

import apple from 'assets/icons/quiz/apple.svg';
import appleOrange from 'assets/icons/quiz/apple-o.svg';
import run from 'assets/icons/quiz/run.svg';
import runOrange from 'assets/icons/quiz/run-o.svg';
import brain from 'assets/icons/quiz/brain.svg';
import brainOrange from 'assets/icons/quiz/brain-o.svg';
import challenges from 'assets/icons/quiz/challenges.svg';
import challengesOrange from 'assets/icons/quiz/challenges-o.svg';
import progress from 'assets/icons/quiz/progress.svg';
import progressOrange from 'assets/icons/quiz/progress-o.svg';

export default {
  greenCheck,
  blueMark,
  redMark,
  yellowMark,
  apple,
  appleOrange,
  run,
  runOrange,
  brain,
  brainOrange,
  challenges,
  challengesOrange,
  progress,
  progressOrange,
};
